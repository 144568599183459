function loadPostAffiliate(callback) {
  const script = document.createElement('script');
  script.src = 'https://aurahealth.postaffiliatepro.com/scripts/owjf63';
  script.id = 'pap_x2s6df8d';
  script.onload = callback;
  document.head.appendChild(script);
}

export default loadPostAffiliate;

