const { format } = require('date-fns');
const { en } = require('date-fns/locale');
const { utcToZonedTime } = require('date-fns-tz');

function displayInTimezone(
  isoDate,
  timezone,
  displayFormat = 'YYYY-MM-dd',
  locale = en
) {
  return format(utcToZonedTime(isoDate, timezone), displayFormat, {
    timeZone: timezone,
    locale,
  });
}

module.exports = {
  displayInTimezone,
};
