import config from "../../config";

const GTM_ID = config.googleTagManagerId;
/**
 * Function to get and set dataLayer
 * @param dataLayer - The dataLayer
 */
const getDataLayerSnippet = () =>
  `dataLayer = []`;
/**
 * Function to get the Iframe snippet
 */
const getIframeSnippet = () => {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
};
/**
 * Function to get the GTM script
 */
const getGTMScript = () => {
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');
  `;
};

export {
  getDataLayerSnippet,
  getIframeSnippet,
  getGTMScript
}
