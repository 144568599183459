import React from 'react';
import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from '../config';

const options = config.bugsnag;
const configuration = { ...options, plugins: [new BugsnagPluginReact()] };
const Bugsnag = bugsnag.start(configuration);

export const ErrorBoundary =
  Bugsnag.getPlugin('react').createErrorBoundary(React);

export default Bugsnag;
