const appConstants = Object.freeze({
  APP_NAME: 'UserWeb',
  DEEPLINK_CHANNEL: 'user_web',
  MODE_PRODUCTION: 'PROD',
  MODE_TEST: 'TEST',
  FB_PREDICTION_CAMPAIGN_ID: '23851110679540342',
  DEFAULT_LOCALE: 'en',
});

module.exports = appConstants;
